<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.customer_name')"
              label-for="customer_name"
            >
              <b-form-input
                id="customer_name"
                :value="searchFields.customer_name"
                @input="inputChange($event, 'customer_name')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.opportunity_description')"
              label-for="opportunity_description"
            >
              <b-form-input
                id="opportunity_description"
                :value="searchFields.opportunity_description"
                @input="inputChange($event, 'opportunity_description')"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"customer-opportunity-hub"'
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'customer-opportunity-hub',
      singularName: 'customer-opportunity-hub',
      searchFields: {
        customer_name: null,
        opportunity_description: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },

}
</script>
